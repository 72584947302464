/* TODO: Delete this h styles */
h1 {
  @apply font-sans text-4xl font-bold;
}

h2 {
  @apply font-sans text-3xl font-bold;
}

h3 {
  @apply font-sans text-2xl font-bold;
}

h4 {
  @apply font-sans text-xl font-bold;
}
