.map {
  height: 90vh;
  border-radius: 6px;
}

.map input {
  border: 1px solid #bdbdbd;
  border-radius: 4px 0px 0px 4px;
  border-right: none;
  /* width: 264px; */
}

.map button {
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-right: none;
  border-radius: 0px;
}

.map button.add-direction {
  border-right: 1px solid #bdbdbd;
}

.map button:first-of-type {
  border-radius: 0px;
  border-right: none;
  border-left: none;
}

.map button:last-of-type {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #bdbdbd;
}

.map button.geolocate-button {
  border-right: 1px solid #bdbdbd;
  border-left: 1px solid #bdbdbd;
  border-radius: 4px !important;
}
